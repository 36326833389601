import React, { useEffect, useContext, useState } from "react";
import DashboardBox from "../../components/DashboardBox/DashboardBox";
import "./SelectedIrpd.scss";
import { useParams, useNavigate } from "react-router";
import Routes from "../../../../routes/routes";
import { ReactJSX } from "../../../../utils/types";
import EditIcon from "@material-ui/icons/Edit";
import { Button, CircularProgress, IconButton, Icon } from "@material-ui/core";
import {
  EditSharp,
  ArrowBack,
  GetApp,
  CachedOutlined,
  AlarmOn,
  AlarmOff,
  Refresh,
} from "@material-ui/icons";
import DashboardBoxTitle from "../../components/DashboardBox/DashboardBoxTitle";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { ApplicationState } from "../../../../redux";
import { hideModal } from "../../../../redux/modal/actions";
import Store from "../../../../redux/index";
import GeneralBox from "../../Irpd/SelectedIrpd/components/IrpdGeneralBox/IrpdGeneralBox";
import { EmptyIrpd } from "../../../../redux/irpds/types";
import HistoricBox from "./components/IrpdHistoric/HistoricBox";
import { isMobile } from "../../../../mobileConfig";
import FilterByDate from "../../../../components/FilterByDate/FilterByDate";
import {
  FilterByDateState,
  FilterTypesChoices,
} from "../../../../redux/filterByDate/types";
import { DrawerContext } from "../../components/DrawerProvider/DrawerProvider";
import {
  MiddleLayoutNavBar,
  FloatingFadedNavBar,
  MobileContainer,
} from "../../Pivot/SelectedPivot/SelectedPivot";
import IrpdMap from "./components/IrpdMap/IrpdMap";
import routes from "../../../../routes/routes";
import { useAnimation } from "framer-motion";
import { useRecoilValue } from "recoil";
import { IrpdFamily } from "../../../../recoils/IrpdRecoil";
import { i18n } from "../../../../i18n/i18nText";
import IrpdHydricInfoTable from "./components/IrpdHydricInfoTable";
import IrpdInfoTable from "./components/IrpdInfoTable/IrpdInfoTable";
import {
  coreHTTPClient,
  getAuthorization,
} from "../../../../services/webclient";
import axios from "axios";
import moment from "moment";
import useNotify from "../../../../hooks/tools/useNotify";
import IrpdWaterConsumptionChart from "./components/IrpdWaterConsumptionChart/IrpdWaterConsumptionChart";
import IrpdWaterPressureChart from "./components/IrpdWaterPressureChart/IrpdWaterPressureChart";
import ConfirmationModal from "../../../Auth/SignUp/ConfirmationModal/ConfirmationModal";
import QuickSelect from "../../../../components/QuickSelect/QuickSelect";

import useSocketIO from "../../../../hooks/tools/useSocketIO";
import { getLatestIrpdStreamV5 } from "../../../../utils/models/irpds";
import useIrpd from "../../../../hooks/models/useIrpd";
const { SOCKET_SUFFIX } = process.env;

var filesaver = require("file-saver");

export const IrpdContext = React.createContext({ irpd: EmptyIrpd() });

export interface IrpdParams {
  farm: string;
  irpd: string;
  pivot?: string;
}

//THIS IS A BOILERPLATE

function SelectedIrpd() {
  const farmID: number = parseInt(useParams().farm, 10);
  const irpdID: number = parseInt(useParams().irpd, 10);
  const [] = useIrpd(farmID, irpdID, true);
  const irpd = useRecoilValue(IrpdFamily(irpdID));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const floatingFadedNavBarAnimation = useAnimation();
  const [sendingExcel, setSendingExcel] = useState(false);
  const [sendingCentral, setSendindCentral] = useState(false);
  const notify = useNotify();
  const [advancedHistoric, setAdvancedHistoric] = useState<boolean>(false);
  const [centralHistoric, setCentralHistoric] = useState<boolean>(false);

  // web socket para atualizar o ícone da bomba no mapa do equipamento no modo mobile -----------------

  const [isConnected, socket] = useSocketIO();

  const [latestIrpdStreamV5, setLatestIrpdStreamV5] = useState(
    irpd?.latest_irpd_stream_v5_event
      ? irpd?.latest_irpd_stream_v5_event
      : irpd?.latest_irpd_stream_v5_periodic
  );

  useEffect(() => {
    if (irpd) {
      setLatestIrpdStreamV5(getLatestIrpdStreamV5(irpd));
    }
  }, [irpd]);

  useEffect(() => {
    if (isConnected) {
      socket.subscribe(`${SOCKET_SUFFIX}irpd@${irpdID}`);

      socket.bind("IrpdStreamV5_event", (data) => {
        setLatestIrpdStreamV5(data);
      });

      socket.bind("IrpdStreamV5_periodic", (data) => {
        setLatestIrpdStreamV5((state) => {
          return { ...state, created: data.created };
        });
      });

      return () => {
        socket.unbind("IrpdStreamV5_event");
        socket.unbind("IrpdStreamV5_periodic");
        socket.unsubscribe(`${SOCKET_SUFFIX}irpd@${irpdID}`);
      };
    }
  }, []);

  // -----------------------------------------------------------------------------------------------------

  var filterByDate: FilterByDateState = useSelector(
    (state: ApplicationState) => state.filterByDate
  );

  const language = useSelector(
    (state: ApplicationState) => state.setting.language,
    shallowEqual
  );
  const toggleAdvancedChecked = (): void => {
    setAdvancedHistoric((prev: boolean) => !prev);
  };

  const toggleCentralChecked = (): void => {
    setCentralHistoric((prev: boolean) => !prev);
  };

  const ctx = useContext(DrawerContext);

  //Estruturas pra suportar o irpdID inline
  let [localIrpdId] = useState(irpdID as any);

  const [openConfirmation, setConfirmation] = useState(false);

  const [reportLoaded, setLoadingReport] = useState(true);

  async function recalculateReport() {
    await new Promise(async (resolve, reject) => {
      await coreHTTPClient
        .post(`/v3/reports/farms/${farmID}/irpd/${irpdID}/recalculate-reports/`)
        .then((res) => {
          setLoadingReport(false);
          resolve(res);
          notify(
            "EDIT_FARM_OPERATION_SUMMARY_RECALCULATE_SUCCESS",
            "success",
            4000
          );
          setLoadingReport(true);
        })
        .catch((err) => {
          reject(err);
          notify("EDIT_FARM_OPERATION_SUMMARY_RECALCULATE_ERROR", "error");
        });
    });
  }

  // Mostra o nome da fazenda e irpd no título da página
  useEffect(() => {
    const farm = Store().store.getState().farm.data;
    irpd
      ? (document.title =
          (farm.name !== undefined ? farm.name + " - " : "") + irpd.name)
      : (document.title = "Irricontrol");
  }, [irpd, reportLoaded]);

  useEffect(() => {
    if (isMobile() && ctx.farmID && localIrpdId)
      navigate(
        routes.DASHBOARD.SELECTED_IRPD.replace(
          ":farm",
          String(ctx.farmID)
        ).replace(":irpd", localIrpdId)
      );
  }, [localIrpdId]);

  useEffect(() => {
    if (localIrpdId) {
      ctx.openSelect();
      ctx.setFarmID(farmID);
      ctx.setIrpdID(localIrpdId);
    }
    // console.log({localIrpdId})
  }, [localIrpdId]);

  useEffect(() => {
    ctx.closeSelect();
    ctx.setIrpds(null);
    ctx.setInicitialOffset(false);
    ctx.setInicitialIrpdId(false);
    ctx.setInicitialCheck(false);
    ctx.setInicitialModels(false);

    return () => {
      ctx.closeSelect();
      ctx.setIrpds(null);
      ctx.setInicitialOffset(false);
      ctx.setInicitialIrpdId(false);
      ctx.setInicitialCheck(false);
      ctx.setInicitialModels(false);
    };
  }, []);

  const buttonBack = (
    <Button
      color="primary"
      size="small"
      onClick={() =>
        navigate(
          Routes.DASHBOARD.SELECTED_FARM.replace(":farm", String(farmID))
        )
      }
      startIcon={<ArrowBack />}
    >
      <>{i18n("SELECTED_PIVOT_BACK_BUTTON_TEXT")}</>
    </Button>
  );

  const rightElements: ReactJSX = (
    <div className="flex-right-container">
      <div>
        <FilterByDate
          title={"SELECTED_PIVOT_HISTORIC_FILTER_PREFERENCES"}
          type={FilterTypesChoices.HISTORIC}
        />
      </div>

      {irpd?.protocol >= 5 ? (
        <div>
          <IconButton
            title={i18n("DEVICE_BOX_SHOW_PERIODIC_STREAMS")}
            aria-label="delete"
            onClick={toggleAdvancedChecked}
            size="small"
          >
            {advancedHistoric ? (
              <AlarmOn fontSize="small" />
            ) : (
              <AlarmOff fontSize="small" />
            )}
          </IconButton>
        </div>
      ) : null}

      <div>
        <IconButton
          title={i18n("SHOW_CENTRAL_STATUS")}
          aria-label="delete"
          onClick={toggleCentralChecked}
          size="small"
        >
          {centralHistoric ? (
            <Icon fontSize="small">public</Icon>
          ) : (
            <Icon fontSize="small">public_off</Icon>
          )}
        </IconButton>
      </div>
    </div>
  );

  /* 
      The two useEffect function beneath are responsible for
      closing the modal (if it's opened) without popping the page
      that is behind the modal.
    */

  var modalActive: boolean = useSelector(
    (state: ApplicationState) => state.modal.active
  );

  useEffect(() => {
    if (modalActive) {
      window.history.pushState(null, null, window.location.href);
    }
  }, [modalActive]);

  useEffect(() => {
    window.onpopstate = function (event) {
      dispatch(hideModal());
    };
  }, []);

  const editButtonHandler = () => {
    navigate(
      Routes.DASHBOARD.EDIT_IRPD.replace(":farm", String(farmID)).replace(
        ":irpd",
        String(irpdID)
      )
    );
  };

  const editButton: ReactJSX = (
    <>
      {irpd?.permission_level === 3 && (
        <Button
          color="primary"
          size="small"
          onClick={editButtonHandler}
          endIcon={<EditSharp />}
        >
          <>{i18n("SELECTED_PIVOT_EDIT_BUTTON_TEXT")}</>
        </Button>
      )}
    </>
  );

  const handleCentralReportClick = () => {
    setSendindCentral(true);
    const url = `/v4/reports/irpds/resume_operation/?irpd_ids=${irpdID}`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${getAuthorization()}`,
        },
        baseURL: process.env.REACTWEB_COREAPI_URL,
      })
      .then(() => {
        notify("IRPD_CENTRAL_GH_REPORT", "success");
      })
      .catch(() => {
        notify("IRPD_CENTRAL_GH_REPORT_ERROR", "error");
      })
      .finally(() => {
        setSendindCentral(false);
      });
  };

  const handleExcelClick = (gh: boolean = false) => {
    setSendingExcel(true);
    var date_start = undefined;
    var date_end = undefined;

    if (
      filterByDate.data.type === FilterTypesChoices.REPORT &&
      filterByDate.data.start_date &&
      filterByDate.data.end_date
    ) {
      date_end = filterByDate.data.end_date.toISOString();
      date_start = filterByDate.data.start_date.toISOString();
    } else {
      date_end = moment().toISOString();
      date_start = moment().subtract(1, "month").toISOString();
    }

    if (isMobile()) {
      setSendingExcel(false);
      // v4/reports/irpds/449/excel/?date_start=2024-06-20T03:00:00.000Z&date_end=2024-07-01T03:00:00.000Z
      const proxyUrl = gh
        ? `/irpds_gh/${irpdID}/excel/?date_start=${date_start}&date_end=${date_end}&name=${
            irpd.name
          }&language=${language}&apikey=${getAuthorization()}`
        : `/irpds/${irpdID}/excel/?date_start=${date_start}&date_end=${date_end}&name=${
            irpd.name
          }&language=${language}&apikey=${getAuthorization()}`;
      window.open(process.env.WEB_URL + proxyUrl);
      // window.open("http://localhost:3000" + proxyUrl);
    } else {
      if (gh) {
        // GH does not support async reports feature
        axios
          .get(
            `/v3/reports/irpds/${irpdID}/excel/?date_start=${date_start}&date_end=${date_end}`,
            {
              headers: {
                Authorization: `Bearer ${getAuthorization()}`,
                "Content-Type":
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                Connection: "close",
              },
              baseURL: process.env.REACTWEB_COREAPI_URL,
              responseType: "blob",
            }
          )
          .then((response) => {
            var blob = new Blob([response.data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            filesaver.saveAs(blob, `Relatório ${irpd.name}.xlsx`);
          })
          .catch((err) => {
            notify("PIVOT_REPORT_EXCEL_ERROR", "error");
          })
          .finally(() => {
            setSendingExcel(false);
          });
      } else {
        axios
          .get(
            `/v3/reports/irpds/${irpdID}/excel/?date_start=${date_start}&date_end=${date_end}`,
            {
              headers: {
                Authorization: `Bearer ${getAuthorization()}`,
                "Content-Type":
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                Connection: "close",
              },
              baseURL: process.env.REACTWEB_COREAPI_URL,
              responseType: "blob",
            }
          )
          .then((response) => {
            var blob = new Blob([response.data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            filesaver.saveAs(blob, `Relatório ${irpd.name}.xlsx`);
          })
          .catch((err) => {
            notify("PIVOT_REPORT_EXCEL_ERROR", "error");
          })
          .finally(() => {
            setSendingExcel(false);
          });
      }
    }
  };

  const excelButton = (
    <>
      <Button
        size="small"
        onClick={() => handleExcelClick()}
        startIcon={sendingExcel ? <CircularProgress size={18} /> : <GetApp />}
        disabled={sendingExcel}
      >
        <>{i18n("PIVOT_REPORT_EXCEL")}</>
      </Button>
    </>
  );

  const excelButton5_3 = (
    <>
      <Button
        size="small"
        onClick={() => handleExcelClick(true)}
        startIcon={sendingExcel ? <CircularProgress size={18} /> : <GetApp />}
        disabled={sendingExcel}
      >
        <>{i18n("PIVOT_REPORT_EXCEL")}</>
      </Button>
      <Button
        size="small"
        color="primary"
        onClick={() => handleCentralReportClick()}
        startIcon={sendingCentral ? <CircularProgress size={18} /> : null}
        disabled={sendingCentral}
      >
        <>{i18n("IRPD_REPORT_CENTRAL")}</>
      </Button>
    </>
  );

  if (irpd !== undefined) {
    return (
      <div className={isMobile() ? "selected-irpd-mobile" : "selected-irpd"}>
        {isMobile() && (
          <FloatingFadedNavBar animate={floatingFadedNavBarAnimation}>
            <ArrowBack
              style={{ marginTop: 15 }}
              onClick={() =>
                navigate(
                  Routes.DASHBOARD.SELECTED_FARM.replace(
                    ":farm",
                    String(farmID)
                  )
                )
              }
            />
            {irpd?.permission_level == 3 ? (
              <EditIcon
                style={{ fontSize: "1.25em", marginTop: 15 }}
                onClick={editButtonHandler}
              />
            ) : null}
          </FloatingFadedNavBar>
        )}
        <IrpdContext.Provider value={{ irpd }}>
          <div className="selected-irpd__grid">
            <div className="selected-irpd__column">
              {isMobile() && (
                <MobileContainer>
                  <IrpdMap
                    latest={latestIrpdStreamV5}
                    irpd={irpd}
                    draggable={false}
                  />
                  <MiddleLayoutNavBar>
                    <div
                      style={{
                        display: "flex",
                        width: "calc(100vw - 45px)",
                        alignItems: "center",
                        gap: "30px",
                        marginLeft: "10px",
                      }}
                    >
                      <QuickSelect farmID={farmID} />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <Refresh
                          style={{
                            color: "white",
                          }}
                          onClick={() => window.location.reload()}
                        />
                      </div>
                    </div>
                  </MiddleLayoutNavBar>
                </MobileContainer>
              )}

              {isMobile() ? (
                <DashboardBox singleElement={<GeneralBox />}>
                  <></>
                </DashboardBox>
              ) : (
                <DashboardBox
                  leftElement={buttonBack}
                  centerElement={
                    <DashboardBoxTitle title={"SELECTED_PIVOT_GENERAL"} />
                  }
                  rightElement={editButton}
                >
                  <GeneralBox />
                </DashboardBox>
              )}

              <DashboardBox
                centerElement={
                  <DashboardBoxTitle title={"SELECTED_PIVOT_HISTORIC"} />
                }
                rightElement={rightElements}
              >
                <HistoricBox
                  advanced={advancedHistoric}
                  central={centralHistoric}
                />
              </DashboardBox>
            </div>

            <div className="selected-irpd__column_single_item">
              {irpd.latest_irpd_config_v5?.has_pressure_sensor ? (
                <IrpdWaterPressureChart farmID={farmID} irpdID={irpdID} />
              ) : null}

              <IrpdWaterConsumptionChart farmID={farmID} irpdID={irpdID} />

              {irpd.protocol === 5.2 ? (
                <DashboardBox
                  centerElement={<h2>{i18n("BASIC_INFO_BOX_TABLE")}</h2>}
                  leftElement={excelButton5_3}
                  rightElement={
                    <>
                      <FilterByDate
                        title={"SELECTED_PIVOT_HISTORIC_FILTER_PREFERENCES"}
                        type={FilterTypesChoices.REPORT}
                      />
                      <IconButton
                        title={i18n("EDIT_FARM_OPERATION_SUMMARY_RECALCULATE")}
                        aria-label="delete"
                        onClick={() => {
                          setConfirmation(true);
                        }}
                        size="small"
                      >
                        <CachedOutlined />
                      </IconButton>
                      <ConfirmationModal
                        openModal={openConfirmation}
                        setModal={setConfirmation}
                        text={
                          "EDIT_FARM_OPERATION_SUMMARY_RECALCULATE_CONFIRMATION"
                        }
                        confirmationButtonText={"SECTOR_PIVOT_REASON_TRUE"}
                        handleConfirmation={() => {
                          recalculateReport();
                        }}
                      />
                    </>
                  }
                >
                  {reportLoaded && irpd && (
                    <IrpdHydricInfoTable
                      farmID={farmID}
                      irpdID={irpdID}
                      regenerate_reports={irpd?.regenerate_reports}
                    />
                  )}
                </DashboardBox>
              ) : (
                <DashboardBox
                  centerElement={<h2>{i18n("BASIC_INFO_BOX_TABLE")}</h2>}
                  leftElement={excelButton}
                  rightElement={
                    <>
                      <FilterByDate
                        title={"SELECTED_PIVOT_HISTORIC_FILTER_PREFERENCES"}
                        type={FilterTypesChoices.REPORT}
                      />
                      <IconButton
                        title={i18n("EDIT_FARM_OPERATION_SUMMARY_RECALCULATE")}
                        aria-label="delete"
                        onClick={() => {
                          setConfirmation(true);
                        }}
                        size="small"
                      >
                        <CachedOutlined />
                      </IconButton>
                      <ConfirmationModal
                        openModal={openConfirmation}
                        setModal={setConfirmation}
                        text={
                          "EDIT_FARM_OPERATION_SUMMARY_RECALCULATE_CONFIRMATION"
                        }
                        confirmationButtonText={"SECTOR_PIVOT_REASON_TRUE"}
                        handleConfirmation={() => {
                          recalculateReport();
                        }}
                      />
                    </>
                  }
                >
                  {reportLoaded && irpd && (
                    <IrpdInfoTable
                      farmID={farmID}
                      irpdID={irpdID}
                      regenerate_reports={irpd?.regenerate_reports}
                    />
                  )}
                </DashboardBox>
              )}
              <footer style={{ marginBottom: "80px", height: "100%" }}></footer>
            </div>
          </div>
        </IrpdContext.Provider>
      </div>
    );
  } else {
    return <></>;
  }
}

export default React.memo(SelectedIrpd);
